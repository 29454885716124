// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-affiliate-jsx": () => import("./../../../src/pages/affiliate.jsx" /* webpackChunkName: "component---src-pages-affiliate-jsx" */),
  "component---src-pages-alternatives-jsx": () => import("./../../../src/pages/alternatives.jsx" /* webpackChunkName: "component---src-pages-alternatives-jsx" */),
  "component---src-pages-black-friday-deals-jsx": () => import("./../../../src/pages/black-friday-deals.jsx" /* webpackChunkName: "component---src-pages-black-friday-deals-jsx" */),
  "component---src-pages-blog-new-jsx": () => import("./../../../src/pages/blog-new.jsx" /* webpackChunkName: "component---src-pages-blog-new-jsx" */),
  "component---src-pages-blog-old-jsx": () => import("./../../../src/pages/blog-old.jsx" /* webpackChunkName: "component---src-pages-blog-old-jsx" */),
  "component---src-pages-blog-write-for-us-jsx": () => import("./../../../src/pages/blog/write-for-us.jsx" /* webpackChunkName: "component---src-pages-blog-write-for-us-jsx" */),
  "component---src-pages-bug-tracking-tool-jsx": () => import("./../../../src/pages/bug-tracking-tool.jsx" /* webpackChunkName: "component---src-pages-bug-tracking-tool-jsx" */),
  "component---src-pages-bug-tracking-tool-pricing-jsx": () => import("./../../../src/pages/bug-tracking-tool-pricing.jsx" /* webpackChunkName: "component---src-pages-bug-tracking-tool-pricing-jsx" */),
  "component---src-pages-bugherd-alternative-jsx": () => import("./../../../src/pages/bugherd-alternative.jsx" /* webpackChunkName: "component---src-pages-bugherd-alternative-jsx" */),
  "component---src-pages-competitor-template-jsx": () => import("./../../../src/pages/competitor-template.jsx" /* webpackChunkName: "component---src-pages-competitor-template-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-customer-feedback-tool-jsx": () => import("./../../../src/pages/customer-feedback-tool.jsx" /* webpackChunkName: "component---src-pages-customer-feedback-tool-jsx" */),
  "component---src-pages-customer-feedback-tool-pricing-jsx": () => import("./../../../src/pages/customer-feedback-tool-pricing.jsx" /* webpackChunkName: "component---src-pages-customer-feedback-tool-pricing-jsx" */),
  "component---src-pages-customer-stories-jsx": () => import("./../../../src/pages/customer-stories.jsx" /* webpackChunkName: "component---src-pages-customer-stories-jsx" */),
  "component---src-pages-design-system-jsx": () => import("./../../../src/pages/design-system.jsx" /* webpackChunkName: "component---src-pages-design-system-jsx" */),
  "component---src-pages-design-system-motion-jsx": () => import("./../../../src/pages/design-system/motion.jsx" /* webpackChunkName: "component---src-pages-design-system-motion-jsx" */),
  "component---src-pages-features-comment-on-website-jsx": () => import("./../../../src/pages/features/comment-on-website.jsx" /* webpackChunkName: "component---src-pages-features-comment-on-website-jsx" */),
  "component---src-pages-features-edit-website-jsx": () => import("./../../../src/pages/features/edit-website.jsx" /* webpackChunkName: "component---src-pages-features-edit-website-jsx" */),
  "component---src-pages-features-integrations-jsx": () => import("./../../../src/pages/features/integrations.jsx" /* webpackChunkName: "component---src-pages-features-integrations-jsx" */),
  "component---src-pages-features-jsx": () => import("./../../../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-features-review-versions-on-websites-jsx": () => import("./../../../src/pages/features/review-versions-on-websites.jsx" /* webpackChunkName: "component---src-pages-features-review-versions-on-websites-jsx" */),
  "component---src-pages-features-review-web-applications-jsx": () => import("./../../../src/pages/features/review-web-applications.jsx" /* webpackChunkName: "component---src-pages-features-review-web-applications-jsx" */),
  "component---src-pages-features-static-image-review-jsx": () => import("./../../../src/pages/features/static-image-review.jsx" /* webpackChunkName: "component---src-pages-features-static-image-review-jsx" */),
  "component---src-pages-features-team-plans-jsx": () => import("./../../../src/pages/features/team-plans.jsx" /* webpackChunkName: "component---src-pages-features-team-plans-jsx" */),
  "component---src-pages-get-started-jsx": () => import("./../../../src/pages/get-started.jsx" /* webpackChunkName: "component---src-pages-get-started-jsx" */),
  "component---src-pages-image-feedback-tool-jsx": () => import("./../../../src/pages/image-feedback-tool.jsx" /* webpackChunkName: "component---src-pages-image-feedback-tool-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-jsx": () => import("./../../../src/pages/integrations.jsx" /* webpackChunkName: "component---src-pages-integrations-jsx" */),
  "component---src-pages-knowledge-box-jsx": () => import("./../../../src/pages/knowledge-box.jsx" /* webpackChunkName: "component---src-pages-knowledge-box-jsx" */),
  "component---src-pages-marker-io-alternative-jsx": () => import("./../../../src/pages/marker-io-alternative.jsx" /* webpackChunkName: "component---src-pages-marker-io-alternative-jsx" */),
  "component---src-pages-markup-io-alternative-jsx": () => import("./../../../src/pages/markup-io-alternative.jsx" /* webpackChunkName: "component---src-pages-markup-io-alternative-jsx" */),
  "component---src-pages-media-kit-jsx": () => import("./../../../src/pages/media-kit.jsx" /* webpackChunkName: "component---src-pages-media-kit-jsx" */),
  "component---src-pages-mobile-app-feedback-jsx": () => import("./../../../src/pages/mobile-app-feedback.jsx" /* webpackChunkName: "component---src-pages-mobile-app-feedback-jsx" */),
  "component---src-pages-mobile-app-feedback-pricing-jsx": () => import("./../../../src/pages/mobile-app-feedback-pricing.jsx" /* webpackChunkName: "component---src-pages-mobile-app-feedback-pricing-jsx" */),
  "component---src-pages-offers-jsx": () => import("./../../../src/pages/offers.jsx" /* webpackChunkName: "component---src-pages-offers-jsx" */),
  "component---src-pages-online-feedback-tool-jsx": () => import("./../../../src/pages/online-feedback-tool.jsx" /* webpackChunkName: "component---src-pages-online-feedback-tool-jsx" */),
  "component---src-pages-online-pdf-annotator-markup-software-jsx": () => import("./../../../src/pages/online-pdf-annotator-markup-software.jsx" /* webpackChunkName: "component---src-pages-online-pdf-annotator-markup-software-jsx" */),
  "component---src-pages-pastel-alternative-jsx": () => import("./../../../src/pages/pastel-alternative.jsx" /* webpackChunkName: "component---src-pages-pastel-alternative-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-privilege-plans-jsx": () => import("./../../../src/pages/privilege-plans.jsx" /* webpackChunkName: "component---src-pages-privilege-plans-jsx" */),
  "component---src-pages-product-updates-jsx": () => import("./../../../src/pages/product-updates.jsx" /* webpackChunkName: "component---src-pages-product-updates-jsx" */),
  "component---src-pages-product-videos-jsx": () => import("./../../../src/pages/product-videos.jsx" /* webpackChunkName: "component---src-pages-product-videos-jsx" */),
  "component---src-pages-producthunt-success-jsx": () => import("./../../../src/pages/producthunt-success.jsx" /* webpackChunkName: "component---src-pages-producthunt-success-jsx" */),
  "component---src-pages-resources-website-redesign-jsx": () => import("./../../../src/pages/resources/website-redesign.jsx" /* webpackChunkName: "component---src-pages-resources-website-redesign-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-support-billing-and-plans-jsx": () => import("./../../../src/pages/support/billing-and-plans.jsx" /* webpackChunkName: "component---src-pages-support-billing-and-plans-jsx" */),
  "component---src-pages-support-get-started-jsx": () => import("./../../../src/pages/support/get-started.jsx" /* webpackChunkName: "component---src-pages-support-get-started-jsx" */),
  "component---src-pages-support-invite-people-jsx": () => import("./../../../src/pages/support/invite-people.jsx" /* webpackChunkName: "component---src-pages-support-invite-people-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-support-tools-and-tips-jsx": () => import("./../../../src/pages/support/tools-and-tips.jsx" /* webpackChunkName: "component---src-pages-support-tools-and-tips-jsx" */),
  "component---src-pages-support-using-ruttl-jsx": () => import("./../../../src/pages/support/using-ruttl.jsx" /* webpackChunkName: "component---src-pages-support-using-ruttl-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-usecases-design-review-jsx": () => import("./../../../src/pages/usecases/design-review.jsx" /* webpackChunkName: "component---src-pages-usecases-design-review-jsx" */),
  "component---src-pages-usecases-for-agencies-jsx": () => import("./../../../src/pages/usecases/for-agencies.jsx" /* webpackChunkName: "component---src-pages-usecases-for-agencies-jsx" */),
  "component---src-pages-usecases-for-designers-and-developers-jsx": () => import("./../../../src/pages/usecases/for-designers-and-developers.jsx" /* webpackChunkName: "component---src-pages-usecases-for-designers-and-developers-jsx" */),
  "component---src-pages-usecases-for-product-companies-jsx": () => import("./../../../src/pages/usecases/for-product-companies.jsx" /* webpackChunkName: "component---src-pages-usecases-for-product-companies-jsx" */),
  "component---src-pages-usecases-for-software-companies-jsx": () => import("./../../../src/pages/usecases/for-software-companies.jsx" /* webpackChunkName: "component---src-pages-usecases-for-software-companies-jsx" */),
  "component---src-pages-usecases-jsx": () => import("./../../../src/pages/usecases.jsx" /* webpackChunkName: "component---src-pages-usecases-jsx" */),
  "component---src-pages-usecases-quality-assurance-jsx": () => import("./../../../src/pages/usecases/quality-assurance.jsx" /* webpackChunkName: "component---src-pages-usecases-quality-assurance-jsx" */),
  "component---src-pages-usecases-visual-feedback-tool-jsx": () => import("./../../../src/pages/usecases/visual-feedback-tool.jsx" /* webpackChunkName: "component---src-pages-usecases-visual-feedback-tool-jsx" */),
  "component---src-pages-usecases-web-design-agencies-jsx": () => import("./../../../src/pages/usecases/web-design-agencies.jsx" /* webpackChunkName: "component---src-pages-usecases-web-design-agencies-jsx" */),
  "component---src-pages-userback-alternative-jsx": () => import("./../../../src/pages/userback-alternative.jsx" /* webpackChunkName: "component---src-pages-userback-alternative-jsx" */),
  "component---src-pages-usersnap-alternative-jsx": () => import("./../../../src/pages/usersnap-alternative.jsx" /* webpackChunkName: "component---src-pages-usersnap-alternative-jsx" */),
  "component---src-pages-video-annotation-tool-jsx": () => import("./../../../src/pages/video-annotation-tool.jsx" /* webpackChunkName: "component---src-pages-video-annotation-tool-jsx" */),
  "component---src-pages-website-annotation-tool-jsx": () => import("./../../../src/pages/website-annotation-tool.jsx" /* webpackChunkName: "component---src-pages-website-annotation-tool-jsx" */),
  "component---src-pages-website-feedback-tool-jsx": () => import("./../../../src/pages/website-feedback-tool.jsx" /* webpackChunkName: "component---src-pages-website-feedback-tool-jsx" */),
  "component---src-pages-website-feedback-tool-pricing-jsx": () => import("./../../../src/pages/website-feedback-tool-pricing.jsx" /* webpackChunkName: "component---src-pages-website-feedback-tool-pricing-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../../../src/templates/blog-list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-customer-story-jsx": () => import("./../../../src/templates/customer-story.jsx" /* webpackChunkName: "component---src-templates-customer-story-jsx" */),
  "component---src-templates-product-update-jsx": () => import("./../../../src/templates/product-update.jsx" /* webpackChunkName: "component---src-templates-product-update-jsx" */)
}

